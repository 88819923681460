/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Image, Button, Subtitle, Text } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Služby"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="--parallax pb--80 pt--80" name={"kontakt"} style={{"backgroundColor":"rgba(248,248,248,1)"}} parallax={true} css={css`
      background-position: 50% 78%;
    `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim7 --anim-s2" anim={"7"} animS={"2"}>
              
              <Title className="title-box fs--62" content={"Služby<br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--50 pt--50" name={"sluzby"} style={{"backgroundColor":"rgba(255, 255, 255, 1)"}}>
          
          <ColumnWrap className="column__flex --left el--2" columns={"2"}>
            
            <ColumnWrapper className="js-anim  --anim2 --anim-s3 pb--0 pt--0" anim={"2"} animS={"3"} style={{"maxWidth":"","backgroundColor":"rgba(248,248,248,1)"}}>
              
              <Image className="mb--0 mt--0 pt--0" src={"https://cdn.swbpg.com/t/10513/9722a84ffe17484ea95550fffcd2638e_s=860x_.jpeg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":""}} srcSet={"https://cdn.swbpg.com/t/10513/9722a84ffe17484ea95550fffcd2638e_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/10513/9722a84ffe17484ea95550fffcd2638e_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/10513/9722a84ffe17484ea95550fffcd2638e_s=860x_.jpeg 860w, https://cdn.swbpg.com/t/10513/9722a84ffe17484ea95550fffcd2638e_s=1400x_.jpeg 1400w, https://cdn.swbpg.com/t/10513/9722a84ffe17484ea95550fffcd2638e_s=2000x_.jpeg 2000w"} position={null}>
              </Image>

              <Title className="title-box title-box--center fs--36" content={"Konzultace /<br>Zaměření"}>
              </Title>

              <Button className="btn-box btn-box--pbtn5 btn-box--center mt--20" use={"page"} href={"/konzultace-zamereni"} content={"Zobrazit"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim2 --anim-s3 pb--0 pt--0" anim={"2"} animS={"3"} style={{"maxWidth":"","backgroundColor":"rgba(248,248,248,1)"}}>
              
              <Image className="mb--0 mt--0 pt--0" src={"https://cdn.swbpg.com/t/10513/7916654651e34e5a937a9844fe011236_s=860x_.jpeg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":""}} srcSet={"https://cdn.swbpg.com/t/10513/7916654651e34e5a937a9844fe011236_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/10513/7916654651e34e5a937a9844fe011236_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/10513/7916654651e34e5a937a9844fe011236_s=860x_.jpeg 860w, https://cdn.swbpg.com/t/10513/7916654651e34e5a937a9844fe011236_s=1400x_.jpeg 1400w, https://cdn.swbpg.com/t/10513/7916654651e34e5a937a9844fe011236_s=2000x_.jpeg 2000w"} position={null}>
              </Image>

              <Title className="title-box title-box--center fs--36" content={"Formátování"}>
              </Title>

              <Button className="btn-box btn-box--pbtn5 btn-box--center mt--20" use={"page"} href={"/formatovani"} content={"Zobrazit"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
          </ColumnWrap>

        </Column>


        <Column className="pb--40 pt--40" name={"paticka"} style={{"backgroundColor":"var(--color-supplementary)"}}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s2 el--3 flex--center" anim={"2"} animS={"2"} style={{"maxWidth":1420}} columns={"3"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box w--500" content={"<span style=\"color: rgb(255, 255, 255);\">Flow Interiér</span><br>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":224}} content={"<span style=\"color: rgb(220, 221, 221);\">Výrobce kvalitních kuchyní a nábytku na míru pro Vaši spokojenost. Realizujeme  po Praze, Středočeském kraji i okolí.</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--right" style={{"maxWidth":""}}>
              
              <Text className="text-box text-box--center" style={{"maxWidth":473}} content={"<span style=\"color: rgb(248, 248, 248);\">IČ 25731491<br>DIČ CZ25731491<br><br>Společnost je vedená u Městského soudu v Praze, spisová značka C 65152</span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--right" style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box w--500 lh--14" content={"<span style=\"color: var(--color-dominant);\">+420 777 206 285<br>info@living4handy.cz<br></span>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":473}} content={"<span style=\"color: rgb(220, 221, 221);\">Living4Handy s.r.o.\n<br>Luženská 2801<br>&nbsp;Rakovník</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}